export const developerData = [
  {
    name: "Rajanshu Ujjwal",
    status: "red",
    skills: ["Product Lead", "Software Architect", "Project Management", "Business"],
    img: "https://avatars.githubusercontent.com/u/19671898?v=4",
    linkedIn: "https://www.linkedin.com/in/thecuriousmonk/",
    github: "https://github.com/theCuriousMonk",
  },
  {
    name: "Bimlendu Kumar",
    status: "green",
    skills: ["React", "React-Native", "Node", "Express", "JavaScript", "TypeScript", "GraphQL", "FrontEnd Design"],
    img:
      "https://avatars.githubusercontent.com/u/50172413?s=460&u=0573967b786828dda99a1efb64dff093fb654f08&v=4",
    linkedIn: "https://www.linkedin.com/in/bim240/",
    github: "https://github.com/bim240",
  },
  // {
  //   name: "Piyush Sinha",
  //   status: "green",
  //   skills: ["Express", "React", "JavaScript", "Node", "Next", "Ruby"],
  //   img:
  //     "https://avatars.githubusercontent.com/u/47945529?s=400&u=2dca37cbd1be6f30cd1152a6d597d8b7f1441793&v=4",
  //   linkedIn: "https://www.linkedin.com/in/inpiyushsinha",
  //   github: "https://github.com/piyushsi",
  // },
  {
    name: "Abhishek Shastri",
    status: "red",
    skills: [
      "Microservices",
      "Vue",
      "JavaScript",
      "UI Design",
      "Cloud Deployment",
    ],
    img: "https://avatars.githubusercontent.com/u/42885835?v=4",
    linkedIn: "https://www.linkedin.com/in/shastri48/",
    github: "https://github.com/shastri48",
  },
  {
    name: "Adarsh Lilha",
    status: "gray",
    skills: ["Backend & API Design", "JavaScript", "Node", "React", "Vue"],
    img: "https://avatars.githubusercontent.com/u/13575704?v=4",
    linkedIn: "https://www.linkedin.com/in/adarshlilha/",
    github: "https://github.com/adarshlilha",
  },
  // {
  //   name: "Md Abu Zafar",
  //   status: "green",
  //   skills: ["Elixir/Phoenix", "Elm", "React", "Svelte", "Rails"],
  //   github: "https://github.com/mdaz78",
  //   linkedIn: "https://www.linkedin.com/in/mdaz78/",
  //   img: "https://avatars.githubusercontent.com/u/4301878?v=4",
  // },
  {
    name: "Komal Raj",
    status: "green",
    skills: ["UI Design", "UI Development","JavaScript", "React"],
    img: "https://avatars.githubusercontent.com/u/41985781?v=4",
    linkedIn: "https://www.linkedin.com/in/komlraj/",
    github: "https://github.com/komlraj",
  },
  {
    name: "Ruchi Kharwar",
    status: "green",
    skills: ["UI Design", "UI Development","JavaScript", "React"],
    img: "https://avatars.githubusercontent.com/u/42887067?v=4",
    linkedIn: "https://www.linkedin.com/in/ruchi-kharwar-713a27139/",
    github: "https://github.com/ruchikh",
  },
  {
    name: "Puneet Tiwari",
    status: "green",
    skills: ["Frontend Development", "Backend & API Design", "JavaScript", "Node", "React"],
    img: "https://avatars.githubusercontent.com/u/53793110?v=4",
    linkedIn: "https://www.linkedin.com/in/puneet-tiwari-97579679/",
    github: "https://github.com/puneettiwari61",
  },
  {
    name: "Reettik Goswami",
    status: "gray",
    skills: ["Frontend Development", "JavaScript", "Node", "React"],
    img: "https://avatars.githubusercontent.com/u/49705550?v=4",
    linkedIn: "https://www.linkedin.com/in/reettik-goswami-457314167/",
    github: "https://github.com/reettikgoswami",
  },
];
