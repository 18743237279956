const theme = {
  colors: {
    backgroundColor: "#e8eff5",
    fontColor: "#525252;",
    complementColor: "#fe851e",
    hoverBackgroundColor: "#d5e2ed",
    secondaryBackgroundColor: "#212A34",
  },
  fontSizes: {
    small: "10px",
    medium: "1rem",
    large: "1.5rem",
    extraLarge: "2.5rem",
  },
};

export default theme;
