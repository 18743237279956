import { StyledFooter } from "./StyledComponents";

const Footer = (props) => {
  return (
    <StyledFooter>
      {" "}
      <h3> Developers' Owned Studio</h3>
      <h5> &#169; HackerLabs </h5>
    </StyledFooter>
  );
};

export default Footer;
